import React, { PropsWithChildren, useEffect, useState } from 'react'

import { useSelector } from 'react-redux'

import isPropValid from '@emotion/is-prop-valid'
import { GlobalStyles } from 'globalStyles'
import { StyleSheetManager, ThemeProvider } from 'styled-components'
import { getTheme } from 'theme'

import { CssBaseline, StyledEngineProvider } from '@mui/material'
import { ThemeProvider as MuiThemeProvider } from '@mui/material/styles'
import { StylesProvider as MuiStylesProvider } from '@mui/styles'

import { getAuthenticated, getRealm } from 'services/api/keycloak/keycloak.selectors'

import { fetchThemeRequest, THEME_GET } from 'services/theme/theme.actions'
import { getColorsPalette } from 'services/theme/theme.selectors'

import { isGraceAppRealm } from 'utils/realmUtils'

import useActionCreator from '../hooks/useActionCreator'
import useLoading from '../services/loading/loading.hook'

const StylesProvider: React.FC<PropsWithChildren> = ({ children }) => {
  const realm = useSelector(getRealm)
  const fetchTheme = useActionCreator(fetchThemeRequest)
  const loading = useLoading([THEME_GET])
  const [fetchingTheme, setFetchingTheme] = useState(false)
  const colors = useSelector(getColorsPalette)
  const theme = getTheme(colors)
  const authenticated = useSelector(getAuthenticated)

  useEffect(() => {
    if (authenticated && isGraceAppRealm(realm)) {
      fetchTheme()
      setFetchingTheme(true)
    }
  }, [authenticated, fetchTheme, realm])

  if ((isGraceAppRealm(realm) && !fetchingTheme) || loading) {
    return null
  }

  return (
    <StyleSheetManager
      shouldForwardProp={(propName, elementToBeRendered) => {
        return typeof elementToBeRendered === 'string' ? isPropValid(propName) : true
      }}
    >
      <MuiStylesProvider>
        <StyledEngineProvider injectFirst>
          <MuiThemeProvider theme={theme}>
            <ThemeProvider theme={theme}>
              {children}
              <CssBaseline />
              <GlobalStyles />
            </ThemeProvider>
          </MuiThemeProvider>
        </StyledEngineProvider>
      </MuiStylesProvider>
    </StyleSheetManager>
  )
}

export default StylesProvider
